export default class Services {
    
constructor()  {}

getHost = () => {

    let aHost = window.location.href.split('/')
    return aHost[2]
    
}

setLangInURL = (currentLang) => {
    const param = 'lang'

    this.deleteInURL(param)

    if(currentLang != 'sk') this.addInURL(param, currentLang)

    return null
}

isURLParameters = () => {
    let path = window.location.href
    let aPath = path.toString().split('?')    
    return Object.keys(aPath).length>1
}

getValueFromURL = (param) => {
    
    let returnValue = ""
    let path = window.location.href
    let aPath = path.toString().split('?')    
    let aParam

    if(Object.keys(aPath).length > 1) {
        let aMembers = aPath[1].split('&') 
        for(let key in aMembers) {
            aParam = aMembers[key].split("=")        
            if(param.trim().toLowerCase()===aParam[0].trim().toLowerCase()) {
                returnValue = aParam[1]
                break
            }
        }
    }

    return returnValue.toString()
}

addInURL = (paramName, value) => {
    const url = new URL(window.location);
    url.searchParams.set(paramName, value);
    window.history.pushState({}, '', url)
}

deleteInURL = (param) => {
    const url = new URL(window.location);
    url.searchParams.delete(param);
    window.history.pushState({}, '', url)
}

getURLParameters = () => {
    let oReturn = []
    let path = window.location.href
    let aPath = path.toString().split('?')
    
    if(Object.keys(aPath).length > 1) {
        let aParam
        let strJSON = "{"
        let aMembers = aPath[1].split('&')
        for(let key in aMembers) {
            aParam = aMembers[key].split("=")
            strJSON += '"' + aParam[0]  + '":"' + aParam[1] + '",'
        }
        strJSON = strJSON.toString().substring(0, strJSON.length-1) + "}"
        oReturn = JSON.parse(strJSON)
    }
    return oReturn
}    
}