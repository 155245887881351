import axios from 'axios'
import OBJ from "@/assets/modules/ObjectMethods.js"
import DT from "@/assets/modules/DateTime.js"
export default class axiosServices {

constructor(template) {
    
    this.OBJ = new OBJ()
    this.DT = new DT()
    this.axios = axios
    this.template = template
    this.returnValue
}

getIPaddress = (dataValueName='') =>   {

    if(typeof dataValueName !== 'string')   return
    if(dataValueName.toString().trim().length===0)  return

    if(typeof this.template.imitationIP !== 'undefined')    {
        if(this.template.imitationIP.toString().trim().length>0)    {
            this.template[dataValueName] = this.template.imitationIP
            return
        }
    }

    let url = '&command=ip-address'

    axios.get(localStorage.REST_API_URL + url)
    .then(response => {
        if(this.OBJ.count(response.data)>0) {
            this.template[dataValueName] = response.data[0].ip
        }
    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}

getConnectRecord = (dataValueName='', ip='', id='' ) => {

    let url = '&command=select&table=isConnect'

    ip = ip.toString()
    id = id.toString()
    
    if(ip.trim().length>0)
        url += '&where=~ip_address="' + ip + '"'

    if(id.trim().length>0)
        url += '&where=~id="' + id + '"'

    axios.get(localStorage.REST_API_URL + url)
    .then(response => {
        if(this.OBJ.count(response.data)>0) this.template[dataValueName] = response.data

    }).catch(function(error) {console.log('CATCH ('+error.message)})   

    //&where=(~from_id=1 AND ~to_id = 2) OR (~from_id=2 AND ~to_id = 1)&order=send&by=asc
}

getBrokerInfo = (dataValueName='') =>   {
    
    if(typeof dataValueName!=='string') return
    if(dataValueName.toString().trim().length===0) return

    let oTempoData = []

    let url = '&command=json&table=isconnect'
    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {    

        if(this.OBJ.count(response.data)>0) {
            for(let record of response.data) {
                if(record.admin>0 && record.broker_id>0)    oTempoData.push(record)
            }
        }
        this.template[dataValueName] = oTempoData

    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}

currentSetConnect = (id=0) =>   {

    if(typeof id!=='string')    return
    id=parseInt(id)
    if(id<1)                    return

    let url = "&change=yes"
    url += "&table=isconnect"
    url += "&command=update"
    url += "&fields=~connect='" + this.DT.stdFromJSDateTime(new Date()) + "'"
    url += "&where=~id="+id


    axios.get(localStorage.REST_API_URL + url)
    .then(response => {
        // || parseInt(response.data[0].affected)!==1
        if(response.data[0].result!=='OK' ) console.log(response.data[0].sql)

    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}

getBrokerRecord = (dataValueName='', id=0)  => {

    if(dataValueName.toString().trim().length===0)   return
    if(parseInt(id)<0)  return

    let url = '&command=json'
    url += '&table=maklers'

    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {
        if(this.OBJ.count(response.data) > 0)   {
            for(let record of response.data)    {
                if(parseInt(record.id)===parseInt(id)) {
                    this.template[dataValueName] = record
                    break
                }
            }
        }

    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}


//==========================================================================================


isBrokerOnline = (broker_id=0, oDataName='') => {

    this.returnValue = false
    if(broker_id < 1) return
    if(oDataName.toString().trim().length === 0) return

    axios.get(localStorage.REST_API_URL + '&command=json&table=isconnect') 
    .then(response => {
        this.returnValue = true

        for(let record of response.data) {
            if(parseInt(record.broker_id)  === parseInt(broker_id)) {
                this.template[oDataName] = record.connect
                break
            }
        }
    }).catch(function(error) {console.log('CATCH ('+error.message)})   
    
    return this.returnValue
}


setConnect = (  currentDate='', 
                ip='',
                host='' )  => {

    if(ip.toString().trim().length===0) return
    if(currentDate.toString().trim().length===0) return

    this.returnValue = false

    let url = '&change=yes'
    url += '&command=rewrite'
    url += '&table=isconnect'
    url += '&fields=connect|' + currentDate
    url += '&where='
    url += 'ip_address|=|' + ip
    url += '|AND|host|=|' + host

    axios.get(localStorage.REST_API_URL + url)
    .then(response => {

        this.returnValue = true

        if(response.data[0].result !== 'OK') console.log(response.data[0].result)
        
        if(response.data[0].affected < 0 && host.indexOf('localhost:')>(-1)) 
            console.log(response.data[0].sql)
    }).catch(function(error) {console.log('CATCH ('+error.message)})   

    return this.returnValue
}



clientIsAdmin = (IP='', oDataName='', oDataNameClientId='', oDataNameClientName='') => {

    if(oDataName.toString().trim().length===0) return false
    if(IP.toString().trim().length===0) return false
    if(oDataNameClientId.toString().trim().length===0) return false
    

    axios.get(localStorage.REST_API_URL + '&command=json&table=isconnect') 
    .then(response => {

        let isAdmin = false
        let clientID = 0
        let clientName = ''
        if(this.OBJ.count(response.data)>0) {

            if(this.OBJ.count(response.data) > 0 )  {
                for(let record of response.data) {
                    if(record.ip_address.toString().trim() === IP.toString().trim()) {
                        isAdmin = parseInt(record.admin) === 1
                        clientID = record.id
                        clientName = record.identify
                        break
                    }
                }
            }

            this.template[oDataName] = isAdmin
            this.template[oDataNameClientId] =  clientID
            this.template[oDataNameClientName] =  clientName
        }

        
    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}

readIPAddress = (oDataName='') => {
    this.returnValue = false
    axios.get(localStorage.REST_API_URL + '&command=address')
    .then(response => {
        if(oDataName.trim().length>0) {
            this.template[oDataName] = response.data
            this.returnValue = true
        }
    }).catch(function(error) {console.log('CATCH ('+error.message)})   
    
    return this.returnValue
}


/*
setConnect = (  currentDate='', 
                ip='',
                host='' )  => {

    if(ip.toString().trim().length===0) return
    if(currentDate.toString().trim().length===0) return

    this.returnValue = false

    let url = '&change=yes'
    url += '&command=update'
    url += '&table=isconnect'
    url += '&fields=connect|' + currentDate
    url += '&where='
    url += 'ip_address|=|' + ip
    url += '|AND|host|=|' + host

    axios.get(localStorage.REST_API_URL + url)
    .then(response => {

        this.returnValue = true

        if(response.data[0].result !== 'OK') console.log(response.data[0].result)
        
        if(response.data[0].affected < 0 && host.indexOf('localhost:')>(-1)) 
            console.log(response.data[0].sql)
    }).catch(function(error) {console.log('CATCH ('+error.message)})   

    return this.returnValue
}
*/






sendMessage = ( sendDate,
                clientId,
                chatBrokerId,
                identify,
                message)    =>  {


    if(sendDate.toString().trim().length===0)           return false
    if(clientId.toString().trim().length<1)             return false
    if( parseInt(clientId)===parseInt(chatBrokerId))    return false
    if(chatBrokerId.toString().trim().length<1)         return false
    if(identify.toString().trim().length===0)           return false
    if(message.toString().trim().length===0)            return false
    

    this.returnValue = 1

    let url =   '&change=yes'
    url +=      '&command=insert&table=livechat'
    url +=      '&fields=send~'+sendDate
    url +=      '|from_id~'+clientId
    url +=      '|to_id~'+chatBrokerId
    url +=      '|identify~'+identify
    url +=      '|message~'+message

    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {
        this.returnValue = 0

        //console.log(response.data)

        if(response.data[0].result !== 'OK')    {console.log(response.data[0].result); this.returnValue = 2}

        if(response.data[0].affected < 1)       {console.log(response.data[0].sql); this.returnValue = 3}

    }).catch(function(error) {console.log('CATCH ('+error.message)})   

    return this.returnValue 
}

newChatClient = (   ip_address,
                    identify,
                    host,
                    connect,
                    admin,
                    broker_id,
                    oDataName) =>   {

    if(ip_address.toString().trim().length===0) return false
    if(identify.toString().trim().length===0)   return false
    if(host.toString().trim().length===0)       return false
    if(connect.toString().trim().length===0)    return false
    if(oDataName.toString().trim().length===0)  return false
    

    let url =       '&change=yes'
        url +=      '&command=insert&table=isconnect'
        url +=      '&fields=ip_address~'+ip_address
        url +=      '|identify~'+identify
        url +=      '|host~'+host
        url +=      '|connect~'+connect
        url +=      '|admin~'+admin
        url +=      '|broker_id~'+broker_id

        
    this.returnValue = 0
    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {
        this.returnValue = true
        this.template[oDataName] = response.data[0].new_id

        if(response.data[0].result !== 'OK')    {console.log(response.data[0].result); this.returnValue = 2}
        if(response.data[0].affected < 1)       {console.log(response.data[0].sql); this.returnValue = 3}
        if(response.data[0].new_id < 1)         {console.log(response.data[0].new_id); this.returnValue = 4}

        //console.log(response.data)
    }).catch(function(error) {console.log('CATCH ('+error.message)})   
                        
    return this.returnValue
}

getMessagesChat = ( clientId, 
                    brokerId,
                    strDataNameValue) =>     {

    if(clientId<1)  return false
    if(brokerId<1)  return false
    if(strDataNameValue.toString().trim().length===0)  return false

    let url =   '&command=select&table=livechat'
    url     +=  '&where=(~from_id='+clientId+' AND ~to_id='+brokerId+') OR (~from_id='+brokerId+' AND ~to_id='+clientId+')'
    url     +=  '&order=send'
    url     +=  '&by=asc'


    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {

        //console.log(typeof response.data)
        //console.log(response.data)
        
        if(this.OBJ.count(response.data)>0) {

            if(response.data[0].send.toString().trim().length>0)    
               this.template[strDataNameValue] = response.data
        }



    }).catch(function(error) {console.log('CATCH ('+error.message)})   
}

getSenders = (strDataNameValue='') =>  {
    
    if(strDataNameValue.toString().trim().length===0) return
    
    let url = '&command=senders'
    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {
        //console.log(response.data)
        if(this.OBJ.count(response.data)>0) this.template[strDataNameValue] = response.data

    })
}

removeSender = (id=0, strDataNameValue='')  =>    {
    if(strDataNameValue.toString().trim().length===0) return
    if(id<2) return

    let url = '&command=removesend&removeid=' + id + '&change=yes'
    axios.get(localStorage.REST_API_URL + url) 
    .then(response => {

        if(this.OBJ.count(response.data)>0) {
            console.log(response.data)
            //setTimeout(() => {this.getSenders(strDataNameValue)}, 1000);
        }
    })
}

}