export default class Services {
    
constructor()  {}

JSONSortOrderInKey(prop) {    
    return function(a, b) {    
      if (a[prop] > b[prop]) {return 1;} else if (a[prop] < b[prop]) {return -1;}    
      return 0;    
    }    
}

sortObjectWithException(    oObject=[],
                            sortKey="",
                            aException=[],
                            orderBy="asc")  {
                
    if(typeof oObject != "object") return []
    if(Object.keys(oObject).length==0) return []
    if(typeof sortKey != "string") return []
    if(sortKey.trim().length==0) return []
    if(typeof aException != "object") return []
    if(typeof orderBy != "string") return []


    let oException = []
    let oTempObject = []
    let oReturn = []
    let isFound = false
    let tempValue = ""

    for(let key in oObject) {
        if(!(sortKey in oObject[key])) return oObject
        isFound  = false
        if(typeof oObject[key][sortKey] == "string") 
            tempValue = oObject[key][sortKey]
        else 
            tempValue = oObject[key][sortKey].toString()

        for(let i=0;i<aException.length;++i) 
            if(tempValue.trim().toLowerCase().indexOf(aException[i].trim().toLowerCase()) == 0) {
                isFound = true
                break
            }

        if(isFound) {oException.push(oObject[key])} else  {oTempObject.push(oObject[key])}
            
    }
    oException = this.sortObject(oException, sortKey, orderBy) 
    oTempObject = this.sortObject(oTempObject, sortKey, orderBy) 

    for(let key in oException) oReturn.push(oException[key])
    for(let key in oTempObject) oReturn.push(oTempObject[key])
    
    return oReturn
}

sortObject = (  oObject, 
                sortKey,
                orderBy="asc") => {

    if(typeof oObject != "object") return []
    if(Object.keys(oObject).length==0) return []
    if(typeof sortKey != "string") return []
    if(sortKey.trim().length==0) return []
    if(typeof orderBy != "string") return []
                


    let oReturn = oObject.sort((key1, key2) => {
        if(orderBy==="desc") {
            if(key1[sortKey] < key2[sortKey]) return 1;
            if(key1[sortKey] > key2[sortKey]) return -1;            
        } else {
            if(key1[sortKey] > key2[sortKey]) return 1;
            if(key1[sortKey] < key2[sortKey]) return -1;            
        }
    
        return 0;        
    })

    return oReturn
}

getRecords = (oObject, inkey, value) => {

    if(this.count(oObject)===0) return []

    let oRecords = []
    let isFound = false

    oObject.forEach(record  => {
        isFound = false
        if(typeof value == "number"  && typeof record[inkey] == "number") {

            if(record[inkey] == value) isFound = true

        }
        if(typeof value == "string"  && typeof record[inkey] == "string") {
            if(record[inkey].trim().toLowerCase() == value.trim().toLowerCase()) isFound = true
        }
        if(isFound) {
            oRecords.push(record)
        }
    })
    return oRecords
}

getRecord = (oObject, inKey, value) => {

    if(this.count(oObject)===0) return []

    let oRecord = []
    let inTypes = false

    for(let key in oObject) {
        inTypes  = false
        if(typeof value == "number") {
            inTypes  = true
            if(parseInt(oObject[key][inKey]) == parseInt(value)) {
                oRecord = oObject[key]
                break       
            }
        }

        if(typeof value == "string") {
            inTypes  = true
            if(oObject[key][inKey].trim().toLowerCase() == value.trim().toLowerCase())  {
                oRecord = oObject[key]
                break       
            }
        }


        if(!inTypes) {
            if(oObject[key][inKey] == value)  {
                oRecord = oObject[key]
                break                       
            }
        }
    }
    
    return oRecord
}

isValueInArray = (oObject,  findValue) =>  {
    
    let isFound = false
    for(let i=0;i<oObject.length;++i) {
        if(findValue == oObject[i]) {
            isFound = true
            break                                    
        }
    }    
    return isFound
}

isValueInObject = (oObject, findKey, findValue) => {
    let isFound = false
    for(let key in oObject) {
        if(oObject[key][findKey] == findValue) {
            isFound = true
            break
        }
    }
    return isFound
}

valueFromKey = (oObject, findKey, findValue, inKey) => {
    
    if(typeof oObject != "object") return ""

    let returnValue = ""

    for(let key in oObject) {
        if(oObject[key][findKey].toString()==findValue.toString()) {
            returnValue = oObject[key][inKey]
            break
        }
    }

    return returnValue
}

getRecordsWhere = (oObject, whereString) => {

    let operand
    let aWhereItems
    if(whereString.indexOf('&&')>(-1)) {
        operand = 'AND'
         aWhereItems = whereString.split('&&')
    }
    
    if(whereString.indexOf('||')>(-1)) {
        operand = 'OR'
         aWhereItems = whereString.split('||')
    }

    let aWhereQuery
    let aFields = []
    let aValues = []
    let index = 1

    for(let i=0;i<aWhereItems.length;++i)   {
        aWhereQuery = aWhereItems[i].split('===')
        for(let j=0;j<aWhereQuery.length;++j)   {
            if(index % 2 > 0)
                aFields.push(aWhereQuery[j])
            else
                aValues.push(aWhereQuery[j])
            ++index
        }
    }

    

    let okRecord
    let oReturnValue = []

    for(let oRecord of oObject) {
        
        
        if(operand === 'AND') okRecord = true
        if(operand === 'OR') okRecord = false
        for(let i=0;i<aFields.length;++i) {
            
            if(operand === 'AND')
                if(oRecord[aFields[i].trim()] !== parseInt(aValues[i].trim())) {
                    okRecord = false
                    break
                }

            if(operand === 'OR')
                if(oRecord[aFields[i].trim()] === parseInt(aValues[i].trim())) {
                    okRecord = true
                    break
                }            
        }
        if(okRecord) oReturnValue.push(oRecord)
    }

    return oReturnValue
}

deleteRecord = (oObject, whereString) => {
    let aWhereItems = whereString.split('&&')
    let aWhereQuery
    let aFields = []
    let aValues = []
    let index = 1

    for(let i=0;i<aWhereItems.length;++i)   {
        aWhereQuery = aWhereItems[i].split('===')
        for(let j=0;j<aWhereQuery.length;++j)   {
            if(index % 2 > 0)
                aFields.push(aWhereQuery[j])
            else
                aValues.push(aWhereQuery[j])
            ++index
        }
    }

    let okRecord
    let oReturnValue = []
    for(let oRecord of oObject) {
        
        okRecord = true
        for(let i=0;i<aFields.length;++i) {
            if(oRecord[aFields[i].trim()] !== parseInt(aValues[i].trim())) {
                okRecord = !okRecord
                break
            }
        }
        if(!okRecord) oReturnValue.push(oRecord)
    }

    return oReturnValue  
}

getObjectFromVuex = (template, VUEXName='') => {
    let VUEXObject = template.$store.state[VUEXName]
    let oReturnObject = []
    
    for(let VUEXRecord of VUEXObject) oReturnObject.push(VUEXRecord)

    return oReturnObject
}




count = (oObject) => {
    
    if(!oObject.length)  
        if(Object.keys(oObject).length > 0) 
            return 1 
        else 
            return 0
            
    return oObject.length
}

}
