

export default class dateTime {

constructor() {}

getTimeLimit = (dateTo='', dateFrom='') => {


    if(typeof dateTo !== 'string' && typeof dateTo !== 'object') return   99999999999999
    if(typeof dateFrom !== 'string' && typeof dateFrom !== 'object') return 99999999999999

    if(typeof dateTo === 'string') {
        if(dateTo.trim().length===0) return 99999999999999
        if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
    }

    if(typeof dateFrom === 'string') {
        if(dateFrom.trim().length===0) return 99999999999999
        if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}    
    }
        

    //console.log('DT to '+dateTo)
    //console.log('DT from '+dateFrom)    
    //console.log(this.dateDiff(dateTo, dateFrom).mlSecs)


    return this.dateDiff(dateTo, dateFrom).mlSecs
}

//------------------------------------------------------------------------------

limitIn = (dateTo, dateFrom, limit=((3*60)*1000)) => {

   
    if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
   
    
    if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}

    let  dateDiff = this.dateDiff(dateTo, dateFrom)

    return limit > dateDiff.mlSecs
}


isJSDateTime = (JSDateTime='') => {
    
    return JSDateTime.toString().indexOf('GMT+') > (-1)
}


stdFromJSDateTime = (JSDateTime) => {

    let years = JSDateTime.getFullYear()
    let months = this.addZero( JSDateTime.getMonth()+1, 2)
    let days = this.addZero( JSDateTime.getDate(), 2)
    let hours = this.addZero( JSDateTime.getHours(), 2)
    let minutes = this.addZero( JSDateTime.getMinutes(),  2)
    let seconds = this.addZero( JSDateTime.getSeconds(),  2)

    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds
}

getSeconds = (dateTime='')  =>  {

    //console.log(this.dateTimeConstructor(dateTime).getTime())

    return this.dateTimeConstructor(dateTime).getTime()
}

dateTimeConstructor = (dateTime='') =>   {

    let aDateTime = dateTime.split(' ')
    let aDate = aDateTime[0].split('-')
    let aTime = aDateTime[1].split(':')

    return new Date(aDate[0], (aDate[1]-1), aDate[2], aTime[0], aTime[1], aTime[2])
}    

dateDiff = (dateTo, dateFrom) => {

    if(!this.isJSDateTime(dateTo)) dateTo = this.dateTimeConstructor(dateTo)
    if(!this.isJSDateTime(dateFrom)) dateFrom = this.dateTimeConstructor(dateFrom)

    let diffTime = Math.abs(dateTo - dateFrom)
    let days = diffTime/(24*60*60*1000)
    let hours = (days % 1) * 24
    let mins = (hours % 1) * 60
    let secs = (mins % 1) * 60;

    let oReturnValues   = {    "mlSecs":diffTime, 
                                "days":days,
                                "hours":hours,
                                "mins":mins,
                                "secs":secs}

    return oReturnValues
}

limitIn = (dateTo, dateFrom, limit=((3*60)*1000)) => {

   
    if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
   
    
    if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}

    let  dateDiff = this.dateDiff(dateTo, dateFrom)

    return limit > dateDiff.mlSecs
}

addZero( paramNumber, lengthNumber) {
    
    let returnNumber = '' + paramNumber

    while (returnNumber.length < lengthNumber) returnNumber = '0' + returnNumber

    return returnNumber
}

SKFormat = (AMDate='', dateOrTime=0, dateSepar='.') => {
    if(AMDate.toString().trim().length===0) return AMDate
    if(!this.isDateTimeAMFormat(AMDate))    return AMDate

    let aDateTime = AMDate.split(' ')
    let aDate = aDateTime[0].split('-')
    let aTime = aDateTime[1].split(':')

    let date = aDate[2] + dateSepar + aDate[1] + dateSepar + aDate[0]
    let time = aTime[0] + ':' + aTime[1] + ':' + aTime[2]
    switch(dateOrTime) {
        case 0: return date
        case 1: return time
        case 2: return time + ' ' + date 
        case 3: return date + ' ' + time
    }

    return date + ' ' + time

}

isDateTimeAMFormat = (dateTime='') => {

    let myRegExp=   /^\d{4}-[0-1][0-2]-[0-3]\d\s([0-1][0-9]|2[0-3]):[0-5]\d$/
    
    if(dateTime.indexOf(':')>(-1) && dateTime.lastIndexOf(':')>(-1)) 
        if(dateTime.indexOf(':') !== dateTime.lastIndexOf(':')) 
            dateTime = dateTime.substring(0, dateTime.lastIndexOf(':'))

    return dateTime.match(myRegExp)
}
    
}