import { createStore } from 'vuex'

export default createStore({
  state: {

    
    oReturnObjectFromAxios:[],

    oFilterLocalities:[],

    oLocalitiesAfterFilter:[],
    oEstatesAfterFilter:[],
    oRoomsAfterFilter:[],
    oBonusesAfterFilter:[],

    oSetEstatesFilter:[],
    oSetRoomsFilter:[],
    oSetBonusesFilter:[],
    oSetLocalitiesFilter:[],

    oFilterID:[],
    reloadTopMenu: '',
    reloadMobileMenu: '',

    oOffers:[],

    oOfferStruct:[],

    oSale:[],
    oPurchase:[],
    oRent:[],
    oRentLook:[],
    oAll:[],


    oEstates:[],
    oStates:[],
    oBuild:[],
    oMaklers:[],
    oLocality:[],
    oDistricts:[],
    oRegions:[],

    oCurrLocality:[],

    rerender:0
  },

  getters: {

    oFilterIDIsEmpty(state) {return state.oFilterID.length ==0},

  },

  mutations: {

    
    oReturnObjectFromAxios(state, oReturnObjectFromAxios) {
      state.oReturnObjectFromAxios = oReturnObjectFromAxios
    },    
    
    oFilterLocalitiesFill(state, oFilterLocalities) {
      state.oFilterLocalities = oFilterLocalities
    },

    oLocalitiesAfterFill(state, oLocalitiesAfterFilter) {
      state.oLocalitiesAfterFilter = oLocalitiesAfterFilter
    },

    oEstatesAfterFill(state, oEstatesAfterFilter) {
      state.oEstatesAfterFilter = oEstatesAfterFilter
    },

    oRoomsAfterFill(state, oRoomsAfterFilter) {
      state.oRoomsAfterFilter = oRoomsAfterFilter
    },

    oBonusesAfterFill(state, oBonusesAfterFilter) {
      state.oBonusesAfterFilter = oBonusesAfterFilter
    },    
    


    oSetEstatesFilterFill(state, oEstatesFilter) {
      state.oSetEstatesFilter = oEstatesFilter
    },

    oSetRoomsFilterFill(state, oRoomsFilter) {
      state.oSetRoomsFilter = oRoomsFilter
    },

    oSetBonusesFilterFill(state, oBonusesFilter) {
      state.oSetBonusesFilter = oBonusesFilter
    },

    oSetLocalitiesFilterFill(state, oSetLocalitiesFilter) {
      state.oSetLocalitiesFilter = oSetLocalitiesFilter
    },    

    
    oOfferStructFill(state, oStruct) {
      state.oOfferStruct = oStruct
    },



    
    oSaleFill(state, oSale) {
      state.oSale = oSale
    },
    oPurchaseFill(state, oPurchase) {
      state.oPurchase = oPurchase
    },
    oRentFill(state, oRent) {
      state.oRent = oRent
    },
    oRentLookFill(state, oRentLook) {
      state.oRentLook = oRentLook
    },
    oAllFill(state, oAll) {
      state.oAll = oAll
    },
    

    oFilterIDFill(state, oFilterID) {
      state.oFilterID = oFilterID
    },

    rerenderSet(state, rerender) {
      state.rerender = rerender
    },

    oCurrLocalityFill(state, oCurrLocality) {
      state.oStates = oCurrLocality
    },        

    oStatesFill(state, oStates) {
      state.oStates = oStates
    },        

    oBuildFill(state, oBuild) {
      state.oBuild = oBuild
    },            

    oMaklersFill(state, oMaklers) {
      state.oMaklers = oMaklers
    },            

    oOffersFill(state, oOffers) {
      state.oOffers = oOffers
    },    
    oEstatesFill(state, oEstates) {
      state.oEstates = oEstates
    },        
    oLocalityFill(state, oLocality) {
      state.oLocality = oLocality
    },
    oDistrictsFill(state, oDistricts) {
      state.oDistricts = oDistricts
    },    
    oRegionsFill(state, oRegions) {
      state.oRegions = oRegions
    },        
    
    changeTopMenuNew(state, newValue) {
      state.reloadTopMenu = newValue
    },
    changeMobileMenuNew(state, newValue) {
      state.reloadMobileMenu = newValue
    },    
  },

  actions: {},
  modules: {}
})
