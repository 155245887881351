export default class System {
    
    constructor()  {}

    isSmallScreen() {
        return screen.width < 960
    }

    inTags(text)  {

        text = text.replaceAll('~i~', '<i>')
        text = text.replaceAll('~/i~', '</i>')
        text = text.replaceAll('~b~', '<b>')
        text = text.replaceAll('~/b~', '</b>')
        text = text.replaceAll('~u~', '<u>')
        text = text.replaceAll('~/u~', '</u>')
        return text
    }

    projectName()  {return '-'+localStorage.projectName}
    
}