export default {
  "appel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "chat": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máte na nás otázku"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nám veľmi ľúto,"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ale všetci naši makléri sú "])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálne je"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím pýtajte sa"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade, ak sme momentálne"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a potrebujete nás osloviť, je tu niekoľko ďalších možností."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete nás telefonicky kontaktovať na čísle"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiež nás môžete kontaktovať aj na mail"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prípadne sa nám predstavte napríkad svojim mailom alebo iným menom, a "])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zanechajte nám správu"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše meno alebo mail aspoň 5 znakov"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predstavte sa nám prosím"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete nám poslať správu"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlite nám správu"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezabudnite na Vás zanechať kontakt"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša správa nám bola úspešne doručená, a ďakujeme Vám za jej zaslanie"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Vašu správu budeme reagovať hneď ako budeme"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade, ak sa nepodárí nadviazať  komunikáciu cestou"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["budeme Vás kontaktovať mailom, telefonicky alebo podľa toho či, a aký kontakt ste nám zaslali"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznáme Vás pod týmto menom"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosíme správu aspoň 10 znakov"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoriť komunikáciu s týmto dopisovateľom"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať dopisovateľa s jeho komunikáciou"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopisovatelia"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť zoznam"])}
  },
  "banners": {
    "banner-1": {
      "snapshot-1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkame vám"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naše služby okolo nehnuteľností"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Sprostredkovanie kúpy a predaja"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Vypracovanie rezervačnej zmluvy"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Vypracovanie prevodnej zmluvy"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Zabezpečíme znalecký posudok"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Vybavíme vklad do katastra"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Zariadime prevod energií"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Vybavíme vám aj výhodnú hypotéku"])}
      },
      "snapshot-2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervačné zmluvy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Pri novom bývaní Vám spoločnosť 818 vie vyhotoviť rezervačnú zmluvu na vybratú nehnuteľnosť."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Keďže nebudete musieť osobne vybavovať potrebné náležitosti okolo rezervácie nehnuteľnosti, k dispozícii Vám zostane viac časového fondu, aj finančných prostriedkov."])}
      },
      "snapshot-3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevodné zmluvy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Spoločnosť 818 Vám zabezpečí vyhotovenie nielen kúpnych zmlúv, ale aj iný druh zmlúv, ak to bude samotný prevod nehnuteľnosti vyžadovať."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  V tomto smere Vám spoločnosť skutočne vie poskytnúť absolútnu profesionalitu."])}
      },
      "snapshot-4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalecký posudok"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  V prípade potreby znaleckého posudku budete mať tiež zabezpečeného príslušného a po všetkých stránkach akceptovateľného znalca. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Ako bonus so znalcom bude dojednaná aktuálne najvýhodnejšia cena na trhu, za vypracovanie znaleckého posudku."])}
      },
      "snapshot-5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad do katastra"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Spoločnosť 818 Vás vie zastúpiť aj v záverečnej fáze prevodu nehnuteľnosti na katastri, čiže pri katastrálnom vkladovom konaní. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Ak bude potrebná ďalšia komunikácia s katastrálnym odborom pri vklade nehnuteľnosti, s príslušným okresným všetky potrebné náležitosti budú dôkladne odkomunikované."])}
      },
      "snapshot-6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie a ich prepis"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  V rámci prevodu nehnuteľnosti, je viazaná spotreba energií, ako napríklad elektrika voda plyn a podobne."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Počas Vášho sťahovania spoločnosť zabezpečí prevod energií, pričom Vy môžete svoj čas plne venovať sťahovaniu."])}
      },
      "snapshot-7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhodná hypotéka"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Vybavíme aj hypotéku presne podľa Vašich potrieb, s tým už máme bohaté mnohoročné skúsenosti."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Tak zabezpečíme to najvýhodnejšie a najideálnejšie financovanie, cestou ktorého môžete svoju novú nehnuteľnosť pokojne nadobudnúť."])}
      }
    }
  },
  "topLine": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 spol. s r.o."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 948 818 818"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte nám"])},
    "1-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 spol. s r.o."])},
    "2-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 948 818 818"])},
    "3-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte nám"])},
    "1-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land Solution s r.o."])},
    "2-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 904 478 738"])},
    "3-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte mi"])}
  },
  "start-site": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť ponuku"])},
    "title-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Želám si nájsť nehnuteľnosť"])},
    "my-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["realitná kancelária"])},
    "makler-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť 818, "])},
    "makler-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váša realitná kancelária"])},
    "makler-name-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť 818, "])},
    "makler-type-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váša realitná kancelária"])},
    "makler-name-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastislav Rehák, "])},
    "makler-type-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo aplikácia vo VUE JS 3"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnovšie ponuky"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 spol. s r.o., výroba: Land Solution spol. s.r.o."])},
    "copy-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 spol. s r.o., výroba: Land Solution spol. s.r.o."])},
    "copy-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastislav Rehák, výroba: Land Solution spol. s.r.o."])}
  },
  "menu": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo ponúkame ?"])},
    "1-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo ponúkame ?"])},
    "1-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo ponúkam ?"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazenie ponúk"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať v ponukách"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečo 818 ?"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečo firma RK ?"])}
  },
  "base-info": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo Vám ponúkame ?"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť 818 ako realitná kancelária patrí medzi exkluzívnych partnerov pre Slovensko ako aj Českú republiku."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytujeme kompletný servis pri predaji, kúpe a prenájme aj nadštandardných rezidenčných alebo komerčných realít nielen v atraktívnych lokalitách Bratislavy, ale aj celého Západného Slovenska, alebo kdekoľvek na Slovensku, prípadne aj v Českej republike - "])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viac info"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša spoločnosť sa snaží vyhovieť všetkým požiadavkám a potrebám našich zákazníkov. Pri našich službách sa výlučne stretnete len s kvalitným a profesionálnym prístupom urobíme všetko pre to, aby ste našli  pre Vás tú najvhodnejšiu nehnuteľnosť so všetkými službami, s ktorými sa na nás obrátite."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na trhu s nehnuteľnosťami ako aj  službami, ktoré s obchodom okolo nehnuteľností prináležia máme už viac ako desaťročné kvalitné skúsenosti, a množstvo spokojných zákazníkov, medzi ktorými môžete byť aj Vy, ak sa rozhodnete naše služby využiť. "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa rozhodnete využívať aj"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ďalšie naše služby"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zároveň tým môžete od nás získať aj množstvo ďalších benefitov."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záverom tejto časti web aplikácie Vám môžeme ponúknuť napríklad aj tieto nehnuteľnosti:"])}
  },
  "favorite": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazenie ponúk"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nehnuteľnosti podľa Vašich kritérii"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadané kritéria nespĺňa žiadna nehnuteľnosť"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetky ponuky"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetky aktuálne ponuky"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše najnovšie ponuky"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpa"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom ponúka"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom hľadá"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne ponuky"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadané číslo ponuky neexistuje !"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálne bez ponuky"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v lokalite"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť detail"])}
  },
  "estate": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREDAJ"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRENÁJOM"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REZIDENCIA"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOMERCIA"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadštandard"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exkluzívne"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervácia"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PONUKA"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KÚPA"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÁJOM HĽADÁ"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcem vedieť viac"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujem za podrobnosti k ponuke"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celý text sa zobrazí po potvrdení myškou"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírovať link na ponuku"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL skopírované"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírujem"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splátka hypotéky"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splátka"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť filter"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skryť filter"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena neuvedená"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoradiť podľa"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najdrahších"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najlacnejších"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnovších"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpa"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom hľada"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom ponúka"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetko"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmery"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť detail"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať lokality"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybraté lokality"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kraj"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okres"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter podľa lokalít"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus typy nehnuteľností"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus počty miestností"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garsónka"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna miest."])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dve miest."])},
    "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tri miest."])},
    "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štyri miest."])},
    "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päť a viac"])},
    "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus bonus filter"])},
    "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novostavba"])}
  },
  "search": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadám"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom ponúka"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nehnuteľnosť"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať štát"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokality"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet izieb"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum cena"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na cene nezáleží"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálna výmera"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera nepodstatná"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať v ponukách"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritéria Vášho záujmu"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpa"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájom hľadá"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okresy"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponuky tohto typu nie sú k dispozícii"])}
  },
  "details": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dočasný obsah - Ďalšie informácie"])}
  },
  "purchase": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je tu záujem kúpiť nehnuteľnosť"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a mala by aspoň približne spĺňať tieto kritéria"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh nehnuteľnosti"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet izieb"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V obci"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V okrese"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V rámci kraja"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálna cena"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plocha nehnuteľnosti"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["približne"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zariadená nehnuteľnosť"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ideálna možnosť"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je podmienkou"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poschodie"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovanie"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet miest"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garáž"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideálna orientácia"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terasa"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadštandardný výhľad"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klimatizácia"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krb"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekreačná oblasť blízko"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera pozemku"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["približne"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazén"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viac kúpelní"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viac spální"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aspoň"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balkón"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodžia"])}
  },
  "viewIcons-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
  "viewIcons-title": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh ponuky"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh nehnuteľnosti"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úžitková plocha"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera pozemku"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet izieb"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V lokalite"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazén k dispozícii"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krb k dispozícii"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na poschodí"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet garáži"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera terasy"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus zariadenie"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti parkovania"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet balkónov"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet lodžií"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera dvora"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus ponuky"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus ponuky"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav ponuky"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kúpelní"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ku rekreačnej oblasti"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadované poschodia"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadaný počet izieb"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovateľná cena do"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadaná výmera"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klimatizované"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhľad orientovaný na"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadštandardný výhľad"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodujúci maklér"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet spální"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kancelárii"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadaný počet kancelárii"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garsoniera"])}
  },
  "viewIcons": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klimatizácia"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozemok"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientácia"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na východ"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na západ"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na juh"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na sever"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severovýchod"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juhovýchod"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juhozápad"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severozápad"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrý"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["výhľad"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užit.plocha"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet spálni"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet izieb"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupeľne"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazén"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je súčasťou"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krb "])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je súčasťou"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poschodie"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garáže"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terasa"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zariadenie"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovanie"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesta"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balkón"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodžia"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvor"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalita"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novostavba"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekonštrukcia"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpa"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopyt prenájmu"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponuka prenájmu"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxus"])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exkluzívne"])},
    "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervácia"])},
    "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka"])},
    "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpelne"])},
    "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekreácia"])},
    "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadané poschodie"])},
    "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["až"])},
    "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadané izby"])},
    "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálna cena"])},
    "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadaná plocha"])},
    "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklér"])},
    "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izby"])},
    "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvor"])},
    "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spálne"])},
    "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
    "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
    "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt na makléra"])},
    "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kancelárii"])},
    "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kancelárie"])},
    "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadané kancle"])},
    "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garzónka"])}
  },
  "concat-makler": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktujte makléra"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manažer tejto ponuky"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte správu, aspoň 10 znakov"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail na ktorý si želáte odpoveď"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manažer tejto ponuky"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasielanie správy skončilo s chybou"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa bola úspešne zaslaná"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budeme Vás kontaktovať bez zbytočného meškania"])}
  },
  "addIcon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zariadená"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkaná nahnuteľnosť je zariadená"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezariadená"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkaná nahnuteľnosť je nezariadená"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkaná nehnuteľnosť sa nachádza na poschodí z počtu poschodí"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úžitková plocha ponúkanej nehnuteľnosti"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera dvora alebo záhrady ku nehnuteľnosti"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera dvora, terasy alebo záhrady ku nehnteľnosti"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmera pozemmku ponúkanej nehnuteľnosti"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazén"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súčasťou ponúkanej nehnuteľnosti je bazén"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhľad"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z ponúkanej nehnuteľnosti je nadštandardný výhľad"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garáže"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súčasťou ponúkanej nehnuteľnosti je garáž"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klimatizácia"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkaná nehnuteľnosť obsahuje klimatizáciu"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krb"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súčasťou nehnuteľnosti je aj krb alebo podobné kúrenie"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupeľne"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak ponúkaná nehnuteľnosť obsahuje viac kúpeľní"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na východ"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na západ"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na juh"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na sever"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkaná nehnuteľnosť je orientovná na svetovú stranu"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovanie"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosť parkovania v rámci tejto ponuky"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izby"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet izieb v ponúkanej nehnuteľnosti"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spálne"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je v ponúkanej nehnuteľmnosti viac spálni"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdialenosť rekreačnej oblasti od ponúkanej nehnuteľnosti"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena za ponuku"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena dohodou"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sever a východ"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Východ a juh"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juh a západ"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Západ a sever"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet balkónov"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balkón"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet Lodžií"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodžia"])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav nehnuteľnosti"])}
  },
  "estateType": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodinný dom"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytový dom"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kancelárie"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obch. priestor"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozemok"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administr.budova"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt developera"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický objekt"])}
  },
  "states": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenská republika"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká republika"])}
  },
  "setInLimit": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K nastaveniu ceny nie sú informácie"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K nastaveniu výmeru nie sú informácie"])}
  },
  "gdpr": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešpektujeme Vaše súkromie"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevádzkovateľ predmetnej aplikácie, ako aj spoločnosti pracujúce s touto aplikáciou môžu používať napríklad aj cookies súbory či iné online identifikátory, pri ktorých dochádza k spracovaniu aj Vašich osobných údajov, ktoré môžu byť potrebné pre ďalšiu funkčnosť aplikácie, ako aj jej pohodlné nastavenie."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spracované údaje vzťahujúce sa na užívateľa aplikácie môžu byť použité napríklad za účelom selekcie zobrazovania citlivých informácii."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spracované údaje vzťahujúce sa na užívateľa aplikácie môžu byť použité napríklad za účelom selekcie zobrazovania citlivých informácii.Uvedené údaje taktiež môžu slúžiť aj pre účel vytvárania anonymných štatistík, alebo napríklad aj na zobrazenie personalizovaného obsahu a presonalizovanej reklamy podľa záujmov návštevníka webovej aplikácie."])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade, ak potvrdíte tlačidlo Rozumiem a súhlasím dobrovoľne môžete rozhodnúť, že s vyššie uvedenými podmienkami a úkonmi súhlasíte. V prípade ak s uvedeným v tejto časti nesúhlasíte, môžete stránku opustiť."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesúhlasím"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem a súhlasím"])}
  },
  "maklers": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklér"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "modalSendMessage": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte maklérovi"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte správu, aspoň 10 znakov"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail na ktorý si želáte odpoveď"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklér"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posielate správu k ponuke"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša správa bola úspešne zaslaná príslušnému maklérovi."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklér Vás bude kontaktovať bez zbytočného meškania."])},
    "9-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša správa bola úspešne zaslaná príslušnému maklérovi."])},
    "10-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslovený maklér Vás po doručení Vašej správy bude bez zbytočného meškania kontaktovať."])},
    "9-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša správa bola úspešne zaslaná vývojárovi ponúkajúcemu programovanie vo VUE JS 3."])},
    "10-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslovený vývojár Vás po doručení Vašej správy bude bez zbytočného meškania kontaktovať."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavrieť okno"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasielanie Vašej pošty skončilo s chybou."])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak bude problém pretrvávať, kontaktujte prosím správcu aplikácie."])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte našej agentúre správu, akú máte inú požiadavku na nehnuteľnosť, čo sa Vám na našej aplikáci páči, a čo nie."])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte nám"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majiteľ"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manažer tejto ponuky"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslať správu"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť viac"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť menej"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napíšte nám"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okrem Vašej mailovej adresy, ktorú je potrebné uviesť odporúčame do správy  pripojiť aj ďalšie Vaše kontakty, ako napríklad číslo mobilu alebo napríklad whatsapp."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak máte akúkoľvek otázku ohľadne mojej ponuky v správe min. 10 znakov mi ju prosím pošlite."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývojár"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potvrdením tohto linku ma môžete kontaktovať"])}
  },
  "why-abnet": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečo obchodovať cez firmu RK"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poteší nás, ak nám napíšete"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak Vás ponuka zaujala"])},
    "title": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervačné zmluvy"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevodné zmluvy"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalecký posudok"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad do katastra"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie a ich prepis"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhodná hypotéka"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priateľský prístup"])}
    },
    "content": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri novom bývaní Vám spoločnosť RK vie vyhotoviť rezervačnú zmluvu na vybratú nehnuteľnosť. Keďže nebudete musieť osobne vybavovať potrebné náležitosti okolo rezervácie nehnuteľnosti, k dispozícii Vám zostane viac časového fondu, aj finančných prostriedkov."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť RK Vám zabezpečí vyhotovenie nielen kúpnych zmlúv, ale aj iný druh zmlúv, ak to bude samotný prevod nehnuteľnosti vyžadovať. V tomto smere Vám spoločnosť skutočne vie poskytnúť absolútnu profesionalitu."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby znaleckého posudku budete mať tiež zabezpečeného príslušného a po všetkých stránkach akceptovateľného znalca. Ako bonus so znalcom bude dojednaná aktuálne najvýhodnejšia cena na trhu, za vypracovanie znaleckého posudku. "])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť RK Vás vie zastúpiť aj v záverečnej fáze prevodu nehnuteľnosti na katastri, čiže pri katastrálnom vkladovom konaní. Ak bude potrebná ďalšia komunikácia s katastrálnym odborom pri vklade nehnuteľnosti, s príslušným okresným všetky potrebné náležitosti budú dôkladne odkomunikované."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V rámci prevodu nehnuteľnosti, na ktorú je viazaná spotreba energií, ako napríklad elektrika voda a podobne, počas Vášho sťahovania spoločnosť zabezpečí prevod energií, pričom Vy môžete svoj čas plne venovať sťahovaniu. "])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybavíme aj hypotéku presne podľa Vašich potrieb, s tým už máme bohaté mnohoročné skúsenosti. Tak zabezpečíme  to najvýhodnejšie a najideálnejšie financovanie, cestou ktorého môžete svoju novú nehnuteľnosť pokojne nadobudnúť."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade ak sa dohodneme, že budete niektoré nami ponúkané vyššie uvedené služby využívať, spoločnosť RK Vám poskytne priateľský prístup a vhodnú atmosféru pri našej komunikácii, s dôsledným plnením Vašich požiadaviek ohľadne konkrétnej nehnuteľnosti a jej prevodu."])}
    }
  },
  "why-818": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečo obchodovať cez spoločnosť 818"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poteší nás, ak nám napíšete"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak Vás ponuka zaujala"])},
    "title": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervačné zmluvy"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevodné zmluvy"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalecký posudok"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vklad do katastra"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie a ich prepis"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhodná hypotéka"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priateľský prístup"])}
    },
    "content": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri novom bývaní Vám spoločnosť 818 vie vyhotoviť rezervačnú zmluvu na vybratú nehnuteľnosť. Keďže nebudete musieť osobne vybavovať potrebné náležitosti okolo rezervácie nehnuteľnosti, k dispozícii Vám zostane viac časového fondu, aj finančných prostriedkov."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť 818 Vám zabezpečí vyhotovenie nielen kúpnych zmlúv, ale aj iný druh zmlúv, ak to bude samotný prevod nehnuteľnosti vyžadovať. V tomto smere Vám spoločnosť skutočne vie poskytnúť absolútnu profesionalitu."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby znaleckého posudku budete mať tiež zabezpečeného príslušného a po všetkých stránkach akceptovateľného znalca. Ako bonus so znalcom bude dojednaná aktuálne najvýhodnejšia cena na trhu, za vypracovanie znaleckého posudku. "])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť 818 Vás vie zastúpiť aj v záverečnej fáze prevodu nehnuteľnosti na katastri, čiže pri katastrálnom vkladovom konaní. Ak bude potrebná ďalšia komunikácia s katastrálnym odborom pri vklade nehnuteľnosti, s príslušným okresným všetky potrebné náležitosti budú dôkladne odkomunikované."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V rámci prevodu nehnuteľnosti, na ktorú je viazaná spotreba energií, ako napríklad elektrika voda a podobne, počas Vášho sťahovania spoločnosť zabezpečí prevod energií, pričom Vy môžete svoj čas plne venovať sťahovaniu. "])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybavíme aj hypotéku presne podľa Vašich potrieb, s tým už máme bohaté mnohoročné skúsenosti. Tak zabezpečíme  to najvýhodnejšie a najideálnejšie financovanie, cestou ktorého môžete svoju novú nehnuteľnosť pokojne nadobudnúť."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade ak sa dohodneme, že budete niektoré nami ponúkané vyššie uvedené služby využívať, spoločnosť 818 Vám poskytne priateľský prístup a vhodnú atmosféru pri našej komunikácii, s dôsledným plnením Vašich požiadaviek ohľadne konkrétnej nehnuteľnosti a jej prevodu."])}
    }
  }
}