<template>

    <div v-if="true" class="menu-bg" id="general-menu">

        <div class="menu-in" >
            <div class="menu-in-logo" >
                <span @click="changeMenu('')">
                    <img v-if="projectNameIs==='-818'" :src="httpsPath + 'logos/logo-in.png'" alt="Spoločnosť 818" class=" menu-in-logo-818" style="" />
                </span>
            </div>

            <div class="menu-in-work-space" >
                <div class="menu-in-work-space-in" >
                    <i class='fa-solid fa-mobile-retro'></i>   &nbsp;&nbsp;+421 948 818 818
                </div>
                <div class="menu-in-work-space-in-right" >
                    <img v-if="$i18n.locale=='en'" @click="changeLanguage" :src="httpsPath + 'flags-language/sk.jpg'" alt="" class="menu-in-flag" />
                    <img v-if="$i18n.locale=='sk'" @click="changeLanguage" :src="httpsPath + 'flags-language/en.jpg'" alt="" class="menu-in-flag" />
                </div>                
                <div class="menu-in-menu-space">
                    <div    id="id-details"
                            class="first " 
                            :class="{   'menu':     currentUrl!=='details',
                                        'menu-off': currentUrl==='details'} "                    
                            @click="changeMenu('details')">
                        
                        <span   v-if="projectNameIs==='-818'"
                                id="id-details-in"
                                :class="{'current':currentUrl==='details'}"  >
                                {{$t('menu.4')}}
                        </span>    
                        <span   v-if="projectNameIs==='-abnet'">
                            {{$t('menu.5')}}
                        </span>    
                    </div>        
                    <div    id="id-search"
                            class="" 
                            :class="{  'menu':     currentUrl!=='search',
                                    'menu-off': currentUrl==='search'} " @click="changeMenu('search')">
                        
                        <span   id="id-search-in"
                                :class="{'current':currentUrl==='search'}" >
                            {{$t('menu.3')}}
                        </span>    
                    </div>        
                    
                    <div    id="id-favorite"
                            class="" 
                            :class="{  'menu':     currentUrl!=='favorite',
                                    'menu-off': currentUrl==='favorite'} " @click="changeMenu('favorite')">

                        <span   id="id-favorite-in"  
                                :class="{'current':currentUrl==='favorite'}" >
                            {{$t('menu.2')}}
                        </span>    
                    </div>                            
                    
                    <div    id="id-info"
                            class="" 
                            :class="{  'menu':     currentUrl!=='info',
                                    'menu-off': currentUrl==='info'} " @click="changeMenu('info')">
                        
                        <span   id="id-info-in"
                                :class="{'current':currentUrl==='info'}" >
                            {{$t('menu.1'+projectNameIs)}}
                        </span>    
                    </div>                    
                </div>                
            </div>

        </div>


    </div>
</template>

<script>
    import System from "@/assets/modules/System";
    import url from "@/assets/modules/URLMethods"
    
    export default {
        data() {
            return {
                URL: new url(),

                currentUrl: window.location.href,
                projectNameIs: new System().projectName(),
                httpsPath: localStorage.httpsPath,
            }
        },
        methods: {
            changeMenu(path) {
                this.currentUrl = path
                this.$router.push('/'+path)
            },
            changeLanguage() {
                if(localStorage.lang == 'sk' )   {
                    this.$i18n.locale = 'en'
                        
                }
                if(localStorage.lang == 'en' )   {
                    this.$i18n.locale = 'sk'
                }
                this.URL.setLangInURL(this.$i18n.locale)
                localStorage.lang = this.$i18n.locale
                let rerender = this.$store.state.rerender
                ++rerender
                this.$store.commit('rerenderSet', rerender)    
                
            },
        },
        mounted () {
                let delay=2000
                let step=1000

                let idInfo = document.getElementById('id-info')
                if(idInfo !== null) 
                    idInfo.classList.add('display-no')    

                let idFavorite = document.getElementById('id-favorite')
                if(idFavorite !== null) 
                    idFavorite.classList.add('display-no')    

                let idSearch = document.getElementById('id-search')
                if(idSearch !== null) 
                    idSearch.classList.add('display-no')    

                
                let idDetails = document.getElementById('id-details')
                if(idDetails !== null) 
                    idDetails.classList.add('display-no')    
                

                setTimeout(() => {
                    let idInfo = document.getElementById('id-info')
                    if(idInfo !== null)  {
                        idInfo.classList.remove('display-no')    
                        idInfo.classList.add('display-view')    
                    }

                }, delay);
                delay += step

                setTimeout(() => {
                    let idFavorite = document.getElementById('id-favorite')
                    if(idFavorite !== null)  {
                        idFavorite.classList.remove('display-no')    
                        idFavorite.classList.add('display-view')    
                    }

                }, delay);
                delay += step                

                setTimeout(() => {
                    let idSearch = document.getElementById('id-search')
                    if(idSearch !== null)   {
                        idSearch.classList.remove('display-no')    
                        idSearch.classList.add('display-view')    
                    }
                }, delay);
                delay += step

                setTimeout(() => {
                    let idDetails = document.getElementById('id-details')
                    if(idDetails !== null)  {
                        idDetails.classList.remove('display-no')    
                        idDetails.classList.add('display-view')    
                    }
                }, delay);
                delay += step



        },
    }
</script>

<style lang="scss" scoped>


.menu-bg-full{ 
    clear: both;
    width:100%;
    float:left;
    height:115px;
    background-color: $-menu-bg;    //set
    z-index:500;    
}

.menu-bg{
    clear: both;
    width:100%;
    float:left;
    height:115px;
    background: linear-gradient(180deg, $-menu-bg 12%, rgba(0,212,255,0) 100%, rgba(191,119,193,0) 100%);    //set
    z-index:500;
}




.menu-in    {
    margin:10px auto 80px auto;
    width:80%;
    clear: both;
    height:95px;
    
}

.menu-in-logo   {
    clear:both;
    float:left;
    width:95px;
    height:95px;
    
}

.menu-in-logo-818   {
    width:90px;
    height:auto;
    border-radius:50px;
    cursor: pointer;
    
}

.menu-in-work-space {
    float:right;
    height:95px;
    width:85%;
    
}

.menu-in-work-space-in  {
    width:95%;
    height:40px;
    text-align: right;
    color:$-menu-other;       //set
    font-size:1.5em;
    font-weight:600;
    font-family:$-menu-font;
    padding:5px 0 15px 0;
    clear:both;
    float: left;
    
}

.menu-in-work-space-in-right    {
    width:5%;
    float: left;
    height:40px;
    text-align: right;
    
}

.menu-in-flag   {
    width:25px;
    height:auto;
    cursor: pointer;
    
}

.menu-in-menu-space  {
    clear: both;
    float:right;
    margin:8px 0 10px auto;
    width:98%;
    
}

.menu-off   {
    width:170px;
    float:right;
    margin:0 0 0 10px;
    text-align: left;
    padding:0 0px 0 9px;
    font-family:$-menu-font;    //set
    font-size:1em;
    font-weight:0;
    color:$-menu-col;   //set
    
    border-left:solid 2px $-menu-col;    //set
    
    &.first {
        clear: both;
    }
}

.menu   {
    width:170px;
    float:right;
    margin:0 0 0 10px;
    text-align: left;
    padding:0 0px 0 9px;
    font-family:$-menu-font;    //set
    font-weight:0;
    color:$-menu-col;   //set
    font-size:1em;
    border-left:solid 2px $-menu-col;  //set

    text-decoration: none !important;
    display:  block;
    cursor:pointer;    
    &.first {
        clear: both;
    }
    
}

.menu::after {
    content: '';
    display: block;
    width: 0%;
    height: 1px;
    background: transparent;
    border:0px;
    transition: width .3s;
    -webkit-transition: color .3s; /* For Safari 3.0 to 6.0 */
    transition: color .3s; /* For modern browsers */
}

.menu:hover::after {
    transition: width .3s;
    background: $-menu-light;    //set
    margin: 0;
    border:0px;
    width: 100%;
}

.current    {
    color:$-menu-light;  //set
    padding:3px;
}

.display-no {
    opacity:0;
}

.display-view   {
    opacity:1;
    animation-name: menuView;
    animation-duration: 3s;

}
@keyframes menuView {
    from {opacity:0;}
    to {opacity:1;}    
}

.pack-filter-button {
    height:30px;
    text-align: center;
    clear:both;
    float:left;
    width:100%;
    margin:0 0 0 0;
}

</style>