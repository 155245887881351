<template>
<!--<div>-->

    <div id="id-company-top"  class=" company-top">
        
        <div id="id-items-0"    class="items-0">
        </div>
        <div id="id-items-4"    class="items-4">  
            <img v-if="$i18n.locale=='en'" @click="changeLanguage" :src="httpsPath + 'flags-language/sk.jpg'" alt="" class="currentLang" />
            <img v-if="$i18n.locale=='sk'" @click="changeLanguage" :src="httpsPath + 'flags-language/en.jpg'" alt="" class="currentLang" />
        </div> 
    </div>
<!--</div>--->

</template>

<script>
    import url from "@/assets/modules/URLMethods"
    import System from "@/assets/modules/System";


    export default {
        name: 'CompanyTop',  
        components: {

        },
        computed: {},
        data() {
            return {
                System: new System(),
                URL: new url(),

                projectNameIs: new System().projectName(),

                viewFacebook:true,
                viewInstagram:true,
                vievLinkedIn:true,
                viewTwitter:true,

                httpsPath:localStorage.httpsPath,
            };      
        },  
        created() {
            window.addEventListener('scroll', this.scrollControl);
        },

        methods: {

            changeLanguage() {
                if(localStorage.lang == 'sk' )   {
                    this.$i18n.locale = 'en'
                        
                }
                if(localStorage.lang == 'en' )   {
                    this.$i18n.locale = 'sk'
                }
                this.URL.setLangInURL(this.$i18n.locale)
                localStorage.lang = this.$i18n.locale
                let rerender = this.$store.state.rerender
                ++rerender
                this.$store.commit('rerenderSet', rerender)    
                
            },
        },

        mounted () {
            /*
            setTimeout(() => {
                this.topViewAndHide()
            }, 2000);
            */
        },

    }
</script>

<style lang="scss" scoped>

.fb,.inst, .twit, .in { padding:2px 0 2px 0;}

.currentLang { width:$top-lang-width; height:auto; cursor: pointer;}

.company-top    {
    background-color: $-mobile-menu-top-bg;
    color:yellow;//???
    height:40px;
    font-family:'Brush Script MT';  //???
    font-size:0.9em;
    padding:8px 0 0 0;
    &-icon {
        width:20px;
        height:auto;
        font-size:1.4em;
        color:yellow;
        margin: 0 17px 0 0;;
        cursor:pointer;            
    }
}

.company-top-animation-hide    {
    //border:solid 2px green;
    height:0px;
    padding:0px;
    animation-name: top-hide;
    animation-duration: 0.5s;
}


@keyframes top-hide {
    from {height:40px;}
    to {height:0px;}
}


.company-top-animation-view    {
    //border:solid 2px blue;
    height:40px;
    padding:8px 0 0 0;
    animation-name: top-view;
    animation-duration: 0.3s;
}

@keyframes top-view {
    from {height:0px;}
    to {height:$top-height;}
}


.items    {
    margin:0px;
    border:0px;
    &-0 {
        clear:both;
        float:left;
        text-align:center;
        padding-top:2px;
        display: inline;
        width:90%;
    }
    &-4 { 
        width:10%;
        float:left;
    }
}



</style>