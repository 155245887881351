<template>
    <div>
        <div class="mobile-area  container-fluid " :class="{'mobile-area-open':isOpenMenu}"  style="z-index:1000;" >
            <div @click="openMenu" class="mobile-area-space">

                <img  v-if="projectNameIs==='-818'"     :src="httpsPath + 'logos/logo-in.png'" alt="Spoločnosť 818" class="mobile-area-logo rounded-circle cursor" style="" />

                <img  v-if="projectNameIs==='-abnet'"   :src="httpsPath + 'logos/logoLS.jpg'" alt="Spoločnosť 818" class="mobile-area-logo-abnet  cursor" style="" />

            </div>
            <div class="mobile-area-work-space">
                <div class="mobile-area-work-space-in">
                    <div  :class="{'mobile-area-close-space':!isOpenMenu, 'mobile-area-view-space':isOpenMenu,}">
                        <div @click="closeMenu('info')"  :class="{'mobile-area-inline-off':!isMenu1, 'mobile-area-inline':isMenu1,'mobile-area-item-on':currentUrl==='info'}" class="mobile-area-item">
                            <li>{{$t('menu.1'+projectNameIs)}}</li>
                        </div>
                        <div  @click="closeMenu('favorite')" :class="{'mobile-area-inline-off':!isMenu2, 'mobile-area-inline':isMenu2,'mobile-area-item-on':currentUrl==='favorite'}" class="mobile-area-item">
                            <li>{{$t('menu.2')}}</li>
                        </div>
                        <div  @click="closeMenu('search')" :class="{'mobile-area-inline-off':!isMenu3, 'mobile-area-inline':isMenu3,'mobile-area-item-on':currentUrl==='search'}" class="mobile-area-item">
                            <li>{{$t('menu.3')}}</li>
                        </div>
                        <div  @click="closeMenu('details')" :class="{'mobile-area-inline-off':!isMenu4, 'mobile-area-inline':isMenu4,'mobile-area-item-on':currentUrl==='details'}" class="mobile-area-item">
                            <li v-if="projectNameIs==='-818'">{{$t('menu.4')}}</li>
                            <li v-if="projectNameIs==='-abnet'">{{$t('menu.5')}}</li>
                        </div>        
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
    import System from "@/assets/modules/System";


    export default ({
        name: 'MenuTopMobile',  
        data() {
            return {
                projectNameIs: new System().projectName(),

                currentUrl: window.location.href.split('/')[3],
                isOpenMenu: false,
                isMenu1: false,
                isMenu2: false,
                isMenu3: false,
                isMenu4: false,

                httpsPath: localStorage.httpsPath,
            }
        },
        methods: {
            closeMenu(path) {
                this.isOpenMenu = false
                this.currentUrl = path
                this.$router.push('/' + path)
            },

            openMenu() {
                this.isOpenMenu = !this.isOpenMenu
                if(this.isOpenMenu) {
                    
                    this.isMenu1 = false
                    this.isMenu2 = false 
                    this.isMenu3 = false 
                    this.isMenu4 = false 

                    setTimeout(() => {
                       this.isMenu1 = true 
                    }, 300);
                    setTimeout(() => {
                       this.isMenu2 = true 
                    }, 600);                    
                    setTimeout(() => {
                       this.isMenu3 = true 
                    }, 900);       
                    setTimeout(() => {
                       this.isMenu4 = true 
                    }, 1200);                                                                         
                } else {
                       this.isMenu1 = false
                       this.isMenu2 = false 
                       this.isMenu3 = false 
                       this.isMenu4 = false                     
                }
            }
        },
        computed: {
            getStoreValue() {
                return this.$store.state.reloadMobileMenu
            }
        },
        watch: {
            getStoreValue(newValue, oldValue) {   //
                if(newValue != oldValue && newValue.trim().length>0) {
                    this.currentUrl = newValue
                    this.$store.commit('changeMobileMenuNew', '')
                    this.$forceUpdate();
                }
            }
        },        
    })
</script>

<style lang="scss" scoped>

.mobile-area    {
    padding:0px;
    background-color:$-mobile-menu-bg;    //set
    font-family:$-mobile-menu-font;
    height:55px;
    width:100%;
    transition-duration: 1s;
    -webkit-box-shadow: 6px 14px 47px -8px #000;
    box-shadow: 6px 14px 47px -8px #000;
    transition-property: height;
    clear: both;
    &-open  {
        padding:0px;
        background-color: $-mobile-menu-bg; //set
        width:100%;
        height:250px;
        transition-duration: 1s;
        transition-property: height;
        clear: both;
    }
    &-space {
        width: 100%;
        text-align:center;
        clear:both;
        float:left;
    }
    &-logo  {
        &-abnet {
            width:70x;
            height:auto;
            border-radius: 3px;
            margin: 3px auto;
        }
        width:45px;
        height:auto;
        margin: 3px auto;
    }
    &-work-space {
        width:80%;
        padding:0 0 0 30px;
        float: left;
        &-in    {
            margin:0 auto;
            width:400px;
        }
    }
    &-view-space    { display:inline;}
    &-close-space   { display:none;}
    &-item  {
        margin-top:15px;
        font-size:1.2em;
        font-weight:700;
        color:$-mobile-menu-col;    //set
        clear:both;
        cursor:pointer;        
        &-on   {
            margin-top:15px;
            font-size:1.3em;
            font-weight:700;
            color:$-mobile-menu-curr; //set
            clear:both;
        }
    }
    &-inline { 
        display: block; 
        &-off { 
            display: none;
        } 
    }
}

</style>