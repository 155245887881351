<template>

  <div>
    <div>
        
        <div id="general-spiner" class="spinner bar">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
       </div>        
       <div style="display:none;">
            <img src="https://data.818.sk/shots-bg/shots-1/01-Services.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/02-Reserve.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/03-Transfer.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/04-Expert.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/05-Cadastre.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/06-Energies.jpg" alt="" />
            <img src="https://data.818.sk/shots-bg/shots-1/07-Money.jpg" alt="" />
       </div>
    </div>

    <div id="general-container" class="general-container-hide">
        <div class="d-flex justify-content-center w-100 "  style="">

            <!---
        <div class="companyTop fixed-top">
        <CompanyTop />
        <menu-top />
        </div>
        --->
            

        <!----->
        
        <div class="companyTop fixed-top">
            <niceMenu   />
        </div>

        <div class="companyTop-mobile fixed-top">
            <CompanyTop />
            <menu-top-mobile />
        </div>    

        <div class="test" >
            <router-view  :key="rerender" />
        </div>

        <!--<gdpr />--->
        

        </div>


        <liveChat     />

        

        <div id="set-top-box" class="scroll-top scroll-top-none">
            <i @click="setTop" class="fa-solid fa-circle-up scroll-top-icon"></i>
        </div>

        <!--- fixed-bottom -->
        <div class=" fixed-bottom copyright" >
            &copy;&nbsp;{{$t('start-site.copy-'+getProjectName())}}
        </div>
  
    </div>

  </div>

</template>

<script>

    import niceMenu from "@/components/Menu/NiceMenu.vue"

    import CompanyTop from "@/components/CompanyTop"

    import liveChat from "@/components/Messenger/LiveChat.vue"


    //import MenuTop from "@/components/MenuTop"
    import MenuTopMobile from "@/components/MenuTopMobile"


    //import gdpr from "@/components/Modals/gdpr-modal.vue"

    import axios from 'axios'
    import service from '@/assets/modules/ObjectMethods'
    import urlMethods from "@/assets/modules/URLMethods"

    import System from "./assets/modules/System"

    
    import projectCurrent from "@/assets/system/Project-current"
    import settings_abnet from "@/assets/system/SystemSettings-abnet"
    import settings_818 from "@/assets/system/SystemSettings-818"
    

    export default ({
        components: {
            niceMenu,
            CompanyTop,
            MenuTopMobile,
            liveChat,
            //MenuTop,
            //gdpr
        },
        created() {
            window.addEventListener('scroll', this.setTopBox);
            window.addEventListener('scroll', this.startVideoTemplate);
            document.addEventListener('mousemove', this.startVideoTemplate)
        },
        data() {
            return {
                System: new System(),

                projectNameIs: new System().projectName(),
                mouseOnX:(-1),

                //setSCSS:"@/assets/styles/basicSets-818.scss",

                oTempOffers: [],
                rerender:1,
                startVideo:null,
                
            }
        },
        methods: {

            setTopBox() {
                var doc = document.documentElement;
                var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
                let element

                element = document.getElementById('general-menu')
                if(this.mouseOnX > top) {
                    //console.log('TOP')
                    if(element!==null) {
                        element.classList.remove('menu-none')
                        element.classList.add('menu-view')
                    }
                    this.mouseOnX = top
                }

                if(this.mouseOnX < top  && top > 99) {
                    if(element!==null) {
                        element.classList.remove('menu-view')
                        element.classList.add('menu-none')
                    }                    
                    //console.log('DOWN')
                    this.mouseOnX = top                    
                }

                let setTopBox = document.getElementById('set-top-box')
                if(setTopBox !== null) {
                    if(top > 99) 
                        setTopBox.classList.remove('scroll-top-none')
                    else 
                        setTopBox.classList.add('scroll-top-none')
                }
                /*
                let oldScrollY = window.scrollY;
                window.onscroll = () => {
                    if(oldScrollY < window.scrollY){
                        console.log(" Down");
                    } else {
                        console.log(" Up");
                    }
                    oldScrollY = window.scrollY;
                }
                */
            },


            setTop() {
                //window.scrollTo(0,0)
                window.scrollTo({top: 0, behavior: 'smooth'});
            },

            startVideoTemplate()  {
                
                //console.log(typeof localStorage.startVideo)
                //console.log( localStorage.startVideo)
                if(typeof localStorage.startVideo === 'string') {
                    if(parseInt(localStorage.startVideo)>0) {
                        if(localStorage.localhost === '0' && this.System.isSmallScreen() === false) {
                            //console.log('NOT LOCAL && NOT SMALL SCREEN')
                            clearTimeout(this.startVideo)
                            this.startVideo = setTimeout(() => {
                                this.$store.commit('changeTopMenuNew', "/")
                                this.$store.commit('changeMobileMenuNew', "/")
                                this.$router.push('/')
                            
                            }, localStorage.startVideo)
                        }
                    }
                }
            },
            getData() {
                let oDataArea = [
                    {"commitName":"oOffersFill",      "tableName":"offers"},
                    {"commitName":"oEstatesFill",     "tableName":"estates"},
                    {"commitName":"oDistrictsFill",   "tableName":"districts"},
                    {"commitName":"oLocalityFill",    "tableName":"localities"},
                    {"commitName":"oRegionsFill",     "tableName":"regions"},
                    {"commitName":"oStatesFill",      "tableName":"states"},
                    {"commitName":"oBuildFill",       "tableName":"build"},
                    {"commitName":"oMaklersFill",     "tableName":"maklers"}
                ]

                let index = 1

                let serve = new service()

                axios.get(localStorage.REST_API_URL + '&command=struct&table=offers')
                .then(response => {
                    this.$store.commit('oOfferStructFill', response.data)
                }).catch(function(error) {console.log('CATCH ('+event.tableName+') :: '+error.message)})              

                oDataArea.forEach((event) => {
                    //console.log(localStorage.REST_API_URL + '&command=json&table=' + event.tableName)
                    axios.get(localStorage.REST_API_URL + '&command=json&table=' + event.tableName)
                    .then(response => {

                        if(typeof response.data[0].error === 'string') 
                            console.error(response.data[0].error)    


                        if(event.tableName == 'offers') {
                            

                            //this.$store.commit('oSaleFill', this.getTypeOffers(response.data, 'sale')) 
                            //this.$store.commit('oPurchaseFill', this.getTypeOffers(response.data, 'purchase')) 
                            //this.$store.commit('oRentFill', this.getTypeOffers(response.data, 'rent'))    
                            //this.$store.commit('oRentLookFill', this.getTypeOffers(response.data, 'rentLook'))
                            this.oTempOffers = this.getTypeOffers(response.data, 'validOffers')
              
                            response.data = this.oTempOffers
                        }

                        

                        if(event.tableName == 'offers') {

                            response.data.sort(serve.JSONSortOrderInKey("sort"))
                            response.data.sort(function (a, b) {
                                return a.sort > b.sort;
                            });
                            //this.$store.commit('oOfferStructFill', this.getTypeOffers(response.data, 'sale')) 
                        }

                        this.$store.commit(event.commitName, response.data)

                        if(index == Object.keys(oDataArea).length) {
                            let aHref = window.location.href.split('/')
                            let path = aHref[aHref.length-1]
                            if(path.trim().length > 0) this.rerender += 1
                        }
                        ++index
                    }).catch(function(error) {console.log('CATCH ('+event.tableName+') :: '+error.message)})              
                })
            },

            getTypeOffers(oOffers, type) {
                let oChangeItems = []
                oOffers.forEach(element => {
                    if(type == 'validOffers' && (element.deleteRecord==0 || element.deleteRecord==null))
                        oChangeItems.push(element)      
            
                    if(type == 'sale' && (element.deleteRecord==0 || element.deleteRecord==null) && element.forSale==1)
                        oChangeItems.push(element)      
                    
                    if(type == 'purchase' && (element.deleteRecord==0 || element.deleteRecord==null) && element.forSale==2)
                        oChangeItems.push(element)      

                    if(type == 'rent' && (element.deleteRecord==0 || element.deleteRecord==null) && element.forSale==0) 
                        oChangeItems.push(element)      

                    if(type == 'rentLook' && (element.deleteRecord==0 || element.deleteRecord==null) && element.forSale==3)
                        oChangeItems.push(element)      

                })
                return oChangeItems
            },
    
            setSSLAndStartSite(aException, startSite='') {

                let reLoad = false;

                let startPath = window.location.href
                let isException = false
                for(let i=0;i<aException.length;++i)
                    if(startPath.indexOf(aException[i].trim().toLowerCase()) > (-1)) {
                        isException = !isException
                        break
                    }

                let aPath = startPath.split('/') 

                if(aPath[3].indexOf('?fbclid') > (-1)) {
                    aPath[3] = ''
                    startPath = aPath[0] + '/' +aPath[1] + '/' + aPath[2] + '/' + aPath[3]
                    reLoad = true                    
                }

                //console.log(!isException+' && '+startPath.indexOf('localhost:')===(-1)+' &&  '+startPath.indexOf('https:')===(-1))
                if( !isException && 
                    startPath.indexOf('localhost:')===(-1) && 
                    startPath.indexOf('https:')===(-1)) {
                    
                    aPath[0] = 'https:'
                    
                    startPath = aPath[0] + '/' +aPath[1] + '/' + aPath[2] + '/' + aPath[3]
                    reLoad = true
                }

                if(startSite.trim().length>0) 
                    if(aPath[3].trim().length===0) {
                        reLoad = true
                        startPath = startPath + startSite
                    }
                    
                if(reLoad) window.location.href = startPath
                return startPath
            },
            getProjectName() {
                
                let projectName = ''
                for(let key in projectCurrent) {
                    if(projectCurrent[key].current === 1) {
                        projectName = projectCurrent[key]['project-name']
                        break;
                    }
                }
                
                return projectName
            }
        },

        mounted () {


            setTimeout(() => {
                let element = document.getElementById('general-spiner')    
                element.classList.add('spinner-hide')

                element = document.getElementById('general-container')    
                element.classList.remove('general-container-hide')
                element.classList.add('general-container')
            }, 2500);
            
            localStorage.slideIndex = 1
            localStorage.viewAnimation = ''

            let extendSettings

            switch(this.getProjectName()) {
                case '818' : {
                    extendSettings =  settings_818[0]; 
                    break;
                }
                case 'abnet' : {
                    extendSettings =  settings_abnet[0]; 
                    break;
                }
            }

            localStorage.projectName = this.getProjectName()
            //console.log(localStorage.projectName )

            localStorage.appMail = extendSettings['mail-from-api']

            localStorage.httpsPath  = extendSettings['https-path']
            localStorage.shotsPath  = extendSettings['shots-path']
            localStorage.swgPath    = extendSettings['swg-path']
            

            let REST_API_URL = ''
            REST_API_URL += extendSettings['rest-api-url']
            REST_API_URL += '?project='+extendSettings['project-name']
            REST_API_URL += '&'+extendSettings['key-api-gate']+'='+extendSettings['open-api-gate'] 
            REST_API_URL += '&host=' + window.location.href.split('/')[2]
            REST_API_URL += '&domain='+extendSettings['domain-for-api']
            REST_API_URL += '&db='+extendSettings['db-for-api']
            REST_API_URL += '&prefix='+extendSettings['prefix-for-api']
            localStorage.REST_API_URL = REST_API_URL
            localStorage.lang           = extendSettings.initLang
            localStorage.startVideo     = extendSettings.startVideo

            let aHref = window.location.href.split('/')
            localStorage.rootProject = aHref[0] + '/' + aHref[1] + '/' + aHref[2]

            //console.log(localStorage.rootProject)


            //['papyrus', 'apple', 'apple']
            this.setSSLAndStartSite(extendSettings.exceptionSSL, '')

            let url = new urlMethods()
            if(url.getValueFromURL('lang') === 'en')  {
                this.$i18n.locale = 'en'
                localStorage.lang = this.$i18n.locale
            }

            let aPath = window.location.href.split('/')
            localStorage.http = aPath[0] + aPath[1] + '//'
            localStorage.webURL = aPath[2]

            let localhost = '0'
            if(aPath[2].indexOf('localhost:')>(-1)) localhost = '1'
            localStorage.localhost = localhost

            this.getData()
            
            this.startVideoTemplate()
        },

        unmounted() {
            window.removeEventListener('scroll', this.startVideoTemplate);
            document.removeEventListener('mousemove', this.startVideoTemplate)
            clearTimeout(this.startVideo)
        },        
    })

</script>


<style lang="scss" >
.scroll-top-icon    {
    color: $-set-top-bg;
    background-color: $-set-top-col;
    border-radius: 50px;
    border:0px;
    font-size: 2.8em;
    box-shadow: 10px 10px 25px -3px rgba(0,0,0,0.63);
    -webkit-box-shadow: 10px 10px 25px -3px rgba(0,0,0,0.63);
    -moz-box-shadow: 10px 10px 25px -3px rgba(0,0,0,0.63);
}

.scroll-top-none {
    display: none;
}



.scroll-top {
    padding:0px;
    position: fixed;
    top: 93%;
    right: 10%;
    z-index: 99;
    cursor: pointer;
    width:10px;
    background:transparent !important;
}

//@require('@/assets/styles/basicSets-818.scss')

//@import "@/assets/styles/basicSets<aaa>.scss"; //"@/assets/styles/basicSets-818.scss";

.general-container-hide {
    opacity:0
}

.general-container {
    opacity:1;
    animation-name: effect;
    animation-duration: 4s;
}
@keyframes effect {from {opacity: 0;} to {opacity: 1; }}

/* ==========================================================
 * Spinner
 * =========================================================*/
 .spinner{
    width:100px;
    height:100px;
    margin:30px auto;
    position:relative;
    -webkit-animation: rotateit 1.3s linear infinite;
    -moz-animation: rotateit 1.3s linear infinite;
    animation: rotateit 1.3s linear infinite;
}
@-webkit-keyframes rotateit {
    from {
         -webkit-transform: rotate(360deg);
    }
    to {
         -webkit-transform: rotate(0deg);
    }
}
@-moz-keyframes rotateit {
    from {
         -moz-transform: rotate(360deg);
    }
    to {
         -moz-transform: rotate(0deg);
    }
}
@keyframes rotateit {
    from {
         transform: rotate(360deg);
    }
    to {
         transform: rotate(0deg);
    }
}
/* ================================================
 * Bars
 * ================================================*/
 .spinner.bar div{
    width: 10px;
    height: 30px;
    background-color:$-propeller-bar;
    position: absolute;
    top: 35px;
    left: 45px;
}
.spinner.bar div:nth-child(1){
    -webkit-transform: rotate(0deg) translate(0, -30px);
    -moz-transform: rotate(0deg) translate(0, -30px);
    transform: rotate(0deg) translate(0, -30px);
}
.spinner.bar div:nth-child(2){
    -webkit-transform: rotate(45deg) translate(0, -30px);
    -moz-transform: rotate(45deg) translate(0, -30px);
    transform: rotate(45deg) translate(0, -30px);
    opacity:0.7;
}
.spinner.bar div:nth-child(3){
    -webkit-transform: rotate(90deg) translate(0, -30px);
    -moz-transform: rotate(90deg) translate(0, -30px);
    transform: rotate(90deg) translate(0, -30px);
    opacity:0.6;
}
.spinner.bar div:nth-child(4){
    -webkit-transform: rotate(135deg) translate(0, -30px);
    -moz-transform: rotate(135deg) translate(0, -30px);
    transform: rotate(135deg) translate(0, -30px);
    opacity:0.5;
}
.spinner.bar div:nth-child(5){
    -webkit-transform: rotate(180deg) translate(0, -30px);
    -moz-transform: rotate(180deg) translate(0, -30px);
    transform: rotate(180deg) translate(0, -30px);
    opacity:0.4;
}
.spinner.bar div:nth-child(6){
    -webkit-transform: rotate(225deg) translate(0, -30px);
    -moz-transform: rotate(225deg) translate(0, -30px);
    transform: rotate(225deg) translate(0, -30px);
    opacity:0.3;
}
.spinner.bar div:nth-child(7){
    -webkit-transform: rotate(270deg) translate(0, -30px);
    -moz-transform: rotate(270deg) translate(0, -30px);
    transform: rotate(270deg) translate(0, -30px);
    opacity:0.2;
}
.spinner.bar div:nth-child(8){
    -webkit-transform: rotate(315deg) translate(0, -30px);
    -moz-transform: rotate(315deg) translate(0, -30px);
    transform: rotate(315deg) translate(0, -30px);
    opacity:0.1;
}

.spinner-hide {
    display: none;
    opacity: 0;
    animation-name: spinner-hide-set;
    animation-duration:3s;    
}
@keyframes spinner-hide-set {from {opacity: 1;} to {opacity: 0;display: none;}}


* {
    box-sizing: border-box;
}

.test {
  margin-top:100px;
  width:100%;
  padding: 0;
  
}

.companyTop {
  clear:both;
  float:left;
  border:solid 0px orange ;
  width:100%;
  z-index:500;
}

.menuTop {
  clear:both;
  float:left;
  border:solid 0px yellow ;
  width:100%;  
  margin-top: 25px;
  z-index:100;
}

.companyTop-mobile {
  display:none;
}

@media screen and (max-width: 992px) {

.companyTop-mobile {
  display:inline;
}

.companyTop {
  display:none;
}

}




@media screen and (max-width: 450px) {
    .copyright {font-size:0.9em;font-weight:normal;}

}


.menu-none   {
    position:absolute;
    //opacity:0;
    top:-120px;
    animation-name: set-none;
    animation-duration: .5s;    
}
@keyframes set-none {
    from {top: 0px;}
    to {top: -120px;}
}


.menu-view   {
    position:absolute;
    top:0px;
    //opacity:1;
    animation-name: set-view;
    animation-duration: .5s;        
}
@keyframes set-view {
    from {top: -120px;}
    to {top: 0px;}
}
  
</style>